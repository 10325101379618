@media (min-width: 500px){
  .cookiesInfo {
  position: fixed;
  bottom: 0;
  width: 100vw;
  height: 25vh;
  background-color: #858585;
  z-index: 10;
  display: flex;
  // padding: 1em 0;
  gap: 2em;
  justify-content: space-around;
  align-items: center;
  img {
    height: 100%;
  }

  div {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    p {
      
      font-size: 14px;
      line-height: 1em;
      color: white;
    }
    .buttons {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      gap: 2em;
      margin-right: 2em;
      button,
      a {
        text-align: center;
        line-height: 1.2em;
        padding: 0 0.5em;
        border: none;
        border-radius: 0.5em;
        width: auto;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        background-color: black;
        color: white;
        height: 3em;
      }
    }
  }
}
}
@media (max-width: 500px){
  .cookiesInfo {
    position: fixed;
    bottom: 0;
    width: 100vw;
    // height: auto;
    background-color: #858585;
    z-index: 10;
    display: flex;
    flex-direction: column;
    // padding: 1em 0;
    gap: 2em;
    justify-content: space-around;
    align-items: center;
    padding-bottom: 2em;
    img {
      width: 40vw;
    }
  
    div {
      width: 80%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      p {
        text-align: center;
        font-size: 14px;
        line-height: 1em;
        color: white;
      }
      .buttons {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        gap: 2em;
        // margin-right: 2em;
        button,
        a {
          line-height: 1.2em;
          padding: 0 0.5em;
          border: none;
          border-radius: 0.5em;
          width: auto;
          margin: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          text-decoration: none;
          background-color: black;
          color: white;
          height: 3em;
        }
      }
    }
  }
}
@media (min-width: 1200px){
  .cookiesInfo {
    position: fixed;
    bottom: 0;
    width: 100vw;
    height: 25vh;
    background-color: #858585;
    z-index: 10;
    display: flex;
    // padding: 1em 0;
    gap: 2em;
    justify-content: space-around;
    align-items: center;
    img {
      height: 100%;
    }
  
    div {
      width: 80%;
      display: grid;
      grid-template-columns: 3fr 1fr;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      p {
        
        font-size: 14px;
        line-height: 1em;
        color: white;
      }
      .buttons {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        gap: 2em;
        margin-right: 2em;
        button,
        a {
          line-height: 1.2em;
          padding: 0 0.5em;
          border: none;
          border-radius: 0.5em;
          width: auto;
          margin: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          text-decoration: none;
          background-color: black;
          color: white;
          height: 3em;
        }
      }
    }
  }
}

