@import "./vars.scss";
html {
  overflow-x: hidden;
  
  * {
    text-wrap: balance;
    font-family: Raleway !important;
    scroll-behavior: auto;
  }
}
html::-webkit-scrollbar {
  width: 10px;
  background: var(--color1);
}
::-webkit-scrollbar-thumb {
  background: var(--color2);
  -webkit-border-radius: 1ex;
  -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
}
::-webkit-scrollbar-corner {
  background: #000;
  -webkit-border-radius: 1ex;
}
body {
  margin: 0;
}
@media (min-width: 1199px) {
  header {
    z-index: 2;
    padding: 0 28%;
    position: sticky;
    top: 0;

    background-color: white;
    .headerDesktop {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      display: grid;
      text-align: center;
      display: grid;
      align-items: center;
      .HeaderLink {
        display: flex;
        justify-content: center;
        font-size: 1em;
        color: var(--color4);
        // height: auto;
        text-decoration: none;
        :visited {
          font-size: 1.1em;
          color: var(--color1);
          font-weight: 600;
        }
      }
      .HeaderLink:hover {
        font-size: 1.1em;
        transition: 0.4s;
        color: var(--color1);
        font-weight: 600;
      }
      .logoHeaderLink {
        display: flex;
        justify-content: center;
      }
      .logoHeader {
        justify-content: center;
        display: flex;
        font-size: 1em;
        height: 50%;
        width: 60%;
        background-color: var(--color1);
        padding: 0.4em 0.9em;
        padding-bottom: 0.7em;
        // border-bottom-left-radius: 0.5em;
        // border-bottom-right-radius: 0.5em;
      }
    }
    .headerMobile {
      display: none;
    }
  }
  .aktualnosci {
    width: 80vw;
    height: 100%;
    margin: 0 auto;
    text-align: center;
    position: absolute;
    top: 25em;
    left: 0;
    right: 0;
    transform: scale(1.6);
    -webkit-scrollbar: none;
  }
  button.slick-arrow.slick-next,
  button.slick-arrow.slick-prev {
    z-index: 1;
    position: relative;
  }
  button.slick-arrow.slick-next {
    right: 2% !important;
    // margin-right: 1em;
  }
  button.slick-arrow.slick-prev::before,
  button.slick-arrow.slick-next::before {
    color: black;
    // font-size: 34px;
  }
  .slick-slider > svg {
    color: #000;
    font-size: 2em;
    position: relative;
    transition: 0.5s;
  }
  .slick-slider > svg:hover {
    color: var(--color1);
    transition: 0.5s;
  }
  .slick-slider > svg:first-child {
    color: #000;
    font-size: 2em;
    // left: 2% !important;

    z-index: 1;
  }
  .slick-slider > svg:first-child:hover {
    color: var(--color1);
    transition: 0.5s;
  }
  .slick-slider:last-child {
    color: #000;
    font-size: 2em;
    // right: 2% !important;
    z-index: 1;
  }
  .slick-track {
    height: auto;
  }
}



@media (max-width: 1199px) {
 
  .offcanvas-header, .offcanvas-body{
        background-color: var(--color1) !important;
        padding-left: 4em !important;
        button{
          color: white !important;
          --bs-btn-close-color: white !important;
          opacity: 1;
        }
        a{
          color: white;
          text-decoration: none;
          font-weight: 700;
          font-size: 1.5em;
          margin-bottom: 1.5em;
          padding: .5em;
          width: max-content;
          min-width: fit-content;
          text-align: left;
        }
        a:active{
          
          background-color: var(--color2);
          border-radius: 1em;
        }
      }
  header {
    z-index: 2;
    // padding: 0 28%;
    position: sticky;
    top: 0;
    background-color: white;
    .headerMobile {
      .active{
        color: #000;
          background-color: var(--color2);
      }
      padding: 0 1em;
      
      img{
        width: 45%;
        // background-color: var(--color1);
      }
      nav{
        background-color: white !important;
      }
      button{
        border: none;
        box-shadow: none !important;
      }
    }
    .headerDesktop {
      display: none;
      * {
        display: none;
      }
    }
  }
  .aktualnosci {
    width: 80vw;
    height: 100%;
    margin: 0 auto;
    text-align: center;
    position: absolute;
    top: 25em;
    left: 0;
    right: 0;
    transform: scale(1.6);
    -webkit-scrollbar: none;
  }
  button.slick-arrow.slick-next,
  button.slick-arrow.slick-prev {
    z-index: 1;
    position: relative;
  }
  button.slick-arrow.slick-next {
    right: 2% !important;
    // margin-right: 1em;
  }
  button.slick-arrow.slick-prev::before,
  button.slick-arrow.slick-next::before {
    color: black;
    // font-size: 34px;
  }
  .slick-slider > svg {
    color: #000;
    font-size: 2em;
    position: relative;
    transition: 0.5s;
  }
  .slick-slider > svg:hover {
    color: var(--color1);
    transition: 0.5s;
  }
  .slick-slider > svg:first-child {
    color: #000;
    font-size: 2em;
    // left: 2% !important;

    z-index: 1;
  }
  .slick-slider > svg:first-child:hover {
    color: var(--color1);
    transition: 0.5s;
  }
  .slick-slider:last-child {
    color: #000;
    font-size: 2em;
    // right: 2% !important;
    z-index: 1;
  }
  .slick-track {
    height: auto;
  }
  .navbar-toggler{
    display: flex;
  }
}
@media (max-width: 768px){
  .offcanvas-header, .offcanvas-body{
    background-color: var(--color1) !important;
    padding-left: 4em !important;
    button{
      color: white !important;
      --bs-btn-close-color: white !important;
      opacity: 1;
      width: min-content;
    }
    a{
      color: white;
      text-decoration: none;
      font-weight: 700;
      font-size: 1.5em;
      margin-bottom: 1.5em;
      padding: .5em;
      width: max-content;
      min-width: fit-content;
      text-align: left;
    }
    a:active{
      
      background-color: var(--color2);
      border-radius: 1em;
    }
  }
}